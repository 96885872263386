import { useState, useEffect, useCallback } from 'react';
import api from '../services/api';

const useApi = ({
  params: passedParams,
  apiRequest = api,
} = {}) => {
  const [params, setParams] = useState(passedParams || false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setError(null);
    try {
      setIsLoading(true);
      const res = await apiRequest({ ...params });

      if (res.status >= 200 && res.status < 300) {
        // setTimeout(() => {
        //   setData(res.data);
        // }, 2000);
        setData(res.data);
      }
    } catch (err) {
      setError(err);
    } finally {
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 2000);
      setIsLoading(false);
    }
  }, [params, apiRequest]);

  useEffect(() => {
    if (params) fetchData();
  }, [params, fetchData]);

  return {
    data, setParams, isLoading, error,
  };
};

export default useApi;
