import React, { useRef } from 'react'

const Form = (props) => {

    const lastName  = useRef(null);
    const firstName = useRef(null);
    const phone = useRef(null);
    const email = useRef(null);
    const marketing = useRef(null);
    const submitButton = useRef(null);
    const error = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        const lastNameValue = lastName.current.value;
        const firstNameValue = firstName.current.value;
        const phoneValue = phone.current.value;
        const emailValue = email.current.value;
        const marketingValue = marketing.current.checked;
        
        if (!lastNameValue || !firstNameValue || !phoneValue || !emailValue) {
            error.current.textContent = "Veuillez remplir tous les champs.";
            return;
        }
        
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(emailValue)) {
            error.current.textContent = "Veuillez saisir une adresse email valide.";
            return;
        }
        
        const phoneRegex = /^\d{10}$/; 
        if (!phoneRegex.test(phoneValue)) {
            error.current.textContent = "Veuillez saisir un numéro de téléphone valide (10 chiffres).";
            return;
        }
        
        const body = {
            firstName: firstNameValue,
            lastName: lastNameValue,
            phone: phoneValue,
            email: emailValue,
            marketing: marketingValue
        }

        submitButton.current.disabled = true;
        props.setTriggerWheel(true);
        props.onSubmit(body)
        .catch(err => {
          error.current.textContent = err.response.data.message;
        })
    }

  return (
        <form className='dokaa-form-content' onSubmit={handleSubmit}>
            <input 
                className="dokaa-form-input"
                id="lastName"
                name="lastName"
                ref={lastName}
                placeholder="Nom"
            />
            <input 
                className="dokaa-form-input"
                id="firstName"
                name="firstName"
                ref={firstName}
                placeholder="Prénom"
            />
            <input 
                className="dokaa-form-input"
                id="phone"
                name="phone"
                ref={phone}
                placeholder="Téléphone"
            />
            <input
                className="dokaa-form-input"
                id="email"
                name="email"
                ref={email}
                placeholder="Adresse mail"
                type="email"
            />
            <div className='marketing'>
                <input id="marketing" type='checkbox' ref={marketing}/>
                <label htmlFor='marketing'>J'accepte de recevoir des offres promotionelles de la part de <strong>{props.shop.name}</strong></label>
            </div>
            <button className="dokaa-form-cta" ref={submitButton} type="submit" style={{color: props?.customStyle?.buttonTextColor, '--wheel-button-color': props?.customStyle?.buttonColor }}>
                Tourner la roue
            </button>
            <p className='error' ref={error}></p>
        </form>
  )
}

export default Form