import Axios from 'axios';
import config from '../config';

const api = Axios.create({
  withCredentials: true,
  credentials: 'include',
  baseURL: config.apiUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default api;
