import React, { useEffect, useState } from 'react';

const Wheel = (props) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [hasClicked, setHasClicked] = useState(false);
  
  const {items, triggerWheel} = props;

  const selectItem = () => {
    if (selectedItem === null && !hasClicked) {
        const { selectedItem } = props;

        setSelectedItem(selectedItem);
        setHasClicked(true);

        setTimeout(() => {
            props.onSelectedItem()
        }, 4500);
    }
  };

  const wheelVars = {
    '--nb-item': items?.length,
    '--selected-item': selectedItem
  };

  const spinning = selectedItem !== null ? 'spinning' : '';

  useEffect(() => {
    if (triggerWheel) {
      selectItem();
    }
  // eslint-disable-next-line
  }, [triggerWheel]);

  return (
    <div
    style={{
        '--wheel-border-color': props?.customStyle?.wheelBorderColor,
        '--wheel-text-color': props?.customStyle?.wheelTextColor,
        '--wheel-color': props?.customStyle?.wheelColor,
        '--wheel-item-color': props?.customStyle?.wheelItemColor,
      }}
        className="wheel-container">
        <div className='wheel-logo'>
            <img src={props?.shop?.logo} alt={`${props?.shop?.name} logo`} />
        </div>
        <div className='wheel-selector'>
            <div className='inside' />
        </div>
        <div className={`wheel ${spinning}`} style={wheelVars} >
            {items?.map((item, index) => (
                <div className="wheel-item" key={index} style={{ '--item-nb': index }}>
                    <svg viewBox="0 0 500 500">
                        <path transform="translate(0, -200)" id="curve" fill="transparent" d="M275 0A275 275 90 11275 550 275 275 90 11275 0"/>
                        <text text-anchor="middle" width="500">
                            <textPath xlinkHref="#curve" startOffset="50.5%" fill={props?.customStyle?.wheelTextColor}>
                                {item.digitalOffer ? `-${item.name}${item.discountType === "percent" ? "%" : "€"}` : item.name}
                            </textPath>
                        </text>
                    </svg>
                </div>
            ))}
        </div>
    </div>
);
};

export default Wheel;
