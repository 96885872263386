import Cookies from 'js-cookie';

const generateRandomToken = () => {
    return "" + Date.now() + "" + Math.floor(Math.random() * 100)
}

const retrieveUniqueToken = () => {
    const token = Cookies.get('id');
    if (token) return token
    const randomToken = generateRandomToken()
    Cookies.set('id', randomToken, { expires: 182 });
    return randomToken;
}

export {
    retrieveUniqueToken, generateRandomToken
}