import React, { useEffect, useState } from 'react'

const entranceStyle = {
    maxWidth: "100%",
    opacity: '1',
    transform: 'scale(1)',
    transition: '.6s ease-in all'
}

const leaveStyle = {
    maxWidth: "100%",
    opacity: '0',
    transform: 'scale(0)',
    transition: '.6s ease-out all'
}

const ComponentTransitor = (props) => {

    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setMounted(props.trigger);
        }, 600)
    }, [props.trigger])

  return (
    <div style={props.trigger ? entranceStyle : leaveStyle}>
        {mounted && {...props.children}}       
    </div>
  )
}

export default ComponentTransitor