import React, { useEffect, useRef, useState } from 'react'
import ComponentTransitor from './ComponentTransitor'

const GoogleReview = (props) => {

    const [loadingReview, setLoadingReview] = useState(false)
    const [loadingTrigger, setLoadingTrigger] = useState(false)
    const [loadingForm, setLoadingForm] = useState(false)
    const [loadingCta, setLoadingCta] = useState(true)
    const [loadingResponse, setLoadingResponse] = useState(false)

    const email = useRef(null);
    const marketing = useRef(null);
    const submitButton = useRef(null);
    const triggerButton = useRef(null);
    const error = useRef(null);

    useEffect(() => {
        setLoadingForm(props.loadingThirdPart);
    }, [props])

    function openInNewTab(href) {
        Object.assign(document.createElement('a'), {
          target: '_blank',
          rel: 'noopener noreferrer',
          href: href,
        }).click();
    }

    
    const handleClick = () => {
        openInNewTab(`https://search.google.com/local/writereview?placeid=${props.shop.googlePlaceId}`)
        setLoadingCta(false)
        setLoadingReview(true)
        setTimeout(() => {
            setLoadingReview(false)
            setLoadingTrigger(true)
        },10000)
    }

    const handleTrigger = () => {
        props.setTriggerWheel(true)
        triggerButton.current.disabled = true;
        setTimeout(() => {
            setLoadingTrigger(false)
        },4500)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const emailValue = email.current.value;
        const marketingValue = marketing.current.checked;
        
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(emailValue)) {
            error.current.textContent = "Veuillez saisir une adresse email valide.";
            return;
        }
        
        const body = {
            email: emailValue,
            marketing: marketingValue
        }

        setLoadingResponse(true)
        submitButton.current.disabled = true;
        props.onSubmit(body)
        .then(res => {
            setLoadingResponse(false)
            setLoadingForm(false)
            props.setEnd(true)

        })
        .catch(err => {
          error.current.textContent = err.response.data.message;
          submitButton.current.disabled = false;
          setLoadingResponse(false)
        })
    }



  return (
       <>
            <ComponentTransitor trigger={loadingCta}>
                <div className='dokaa-form-content'>
                    <p className='title'>Votre avis compte</p>
                    <p className='subtitle'>Laissez-nous un avis et repartez avec cadeau</p>
                    <button 
                        className="dokaa-form-cta"
                        onClick={handleClick}
                        style={{color: props?.customStyle?.buttonTextColor, '--wheel-button-color': props?.customStyle?.buttonColor }}
                    >
                        <img
                            src="https://storage.googleapis.com/cdn_dokaa/media/google.png"
                            alt="Google logo"
                        />
                        <span>Donnez un avis Google</span>
                    </button>
                </div>
            </ComponentTransitor>
            <ComponentTransitor trigger={loadingReview}>
                <div className='dokaa-form-content'>
                    <p>Nous vérifions votre avis, ne quittez pas cette page.</p>
                    <div className='loader-container'>
                        <span className="loader"></span>
                    </div>
                    <p>Vous n'avez pas encore donné(e) votre avis ?</p>
                    <button 
                        className="dokaa-form-cta"
                        onClick={handleClick}
                        style={{color: props?.customStyle?.buttonTextColor, '--wheel-button-color': props?.customStyle?.buttonColor }}
                    >
                        <img
                            src="https://storage.googleapis.com/cdn_dokaa/media/google.png"
                            alt="Google logo"
                        />
                        <span>Donnez un avis Google</span>
                    </button>
                </div>
            </ComponentTransitor>
            <ComponentTransitor trigger={loadingTrigger}>
                <div className='dokaa-form-content'>
                    <button 
                        className="dokaa-form-cta"
                        onClick={handleTrigger}
                        ref={triggerButton}
                        style={{color: props?.customStyle?.buttonTextColor, '--wheel-button-color': props?.customStyle?.buttonColor }}
                    >
                        <span style={{margin: '32px'}}>Faire tourner la roue</span>
                    </button>
                </div>
            </ComponentTransitor>
            <ComponentTransitor trigger={loadingForm}>
                <form className='dokaa-form-content' onSubmit={handleSubmit}>
                    <p className='title'>Brâvo, vous avez gagné <strong>{props.selectedItem.name}</strong></p>
                    <p className='subtitle'>Afin de récupérer votre cadeau à utiliser <strong>{props.selectedItem.digitalOffer ? "lors de votre prochaine commande" : "lors de votre prochaine venue"}</strong> saisissez votre email</p>
                    <input
                        className="dokaa-form-input"
                        id="email"
                        name="email"
                        ref={email}
                        placeholder="Adresse e-mail"
                        type="email"
                    />
                    <div className='marketing'>
                        <input id="marketing" type='checkbox' ref={marketing}/>
                        <label htmlFor='marketing'>J'accepte de recevoir des offres promotionelle de la part de <strong>{props.shop.name}</strong></label>
                    </div>
                    <button className="dokaa-form-cta" ref={submitButton} type="submit" style={{color: props?.customStyle?.buttonTextColor, '--wheel-button-color': props?.customStyle?.buttonColor }}>
                        {loadingResponse ? <span className="mini-loader"></span> : "Recuperer mon cadeau" } 
                    </button>
                    <p className='error' ref={error}></p>
                </form>
            </ComponentTransitor>
       </>
  )
}

export default GoogleReview