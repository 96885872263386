import { useEffect, useState } from 'react';
import './App.css';
import Wheel from './components/Wheel';
import config from './config';
import { retrieveUniqueToken } from './token'
import axios from 'axios';
import Form from './components/Form';
import useApi from './hooks/useApi';
import GoogleReview from './components/GoogleReview';
import NoCounterpart from './components/NoCounterpart';

const App = () => {
  const [open, setOpen] = useState(false);
  const [loadingThirdPart, setLoadingThirdPart] = useState(false);
  const [end, setEnd] = useState(false);
  const [triggerWheel, setTriggerWheel] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [token, setToken] = useState(undefined);
  const [selectedGift, setSelectedGift] = useState(undefined);
  const [shop, setShop] = useState({});
  const [wheelId, setWheelId] = useState({});
  const [customStyle, setCustomStyle] = useState(undefined);
  const [pickedCta, setPickedCta] = useState("");

  const wheelData = useApi();

  const { qrCode, position } = window.dokaaParams;

  const handleClick = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const tokenL = retrieveUniqueToken();
    setToken(tokenL);

    !wheelData.data &&
      wheelData.setParams({
        url: "/wheels/randomWheels/" + qrCode,
        method: "POST",
        data: { uuid: tokenL },
      });

    if (!!wheelData.data && !wheelData.isLoading) {
      setItems(wheelData.data.wheel.offers);
      setSelectedItem(wheelData.data.selectedItem);
      setSelectedGift(wheelData.data.wheel.offers[wheelData.data.selectedItem]);
      setShop(wheelData.data.wheel.shopId);
      setWheelId(wheelData.data.wheel._id);
      setCustomStyle(wheelData.data.wheel?.customStyle);
      setPickedCta(wheelData.data?.pickedCta);
    }

    return () => {
      setItems([]);
      setSelectedItem(undefined);
      setSelectedGift(undefined);
      setShop({});
      setWheelId({});
      setCustomStyle(undefined);
      setPickedCta("");
    };
    // eslint-disable-next-line
  }, [open]);

  const onSelectedItem = () => {
    switch (pickedCta.type) {
      case "form":
        setEnd(true);
        break;
      case "google":
        setLoadingThirdPart(true);
        break;
      case "none":
        setEnd(true)
        break;
      default:
        onSelectedItem();
        break;
    }
  };

  const onSubmit = (body) => {
    const data = {
      wheelId: wheelId,
      user: {
        firstName: body.firstName,
        lastName: body.lastName,
        phone: body.phone,
        mail: body.email,
      },
      marketing: body.marketing,
      userSeed: token,
      gift: {
				name: selectedGift.name,
				...(selectedGift.digitalOffer && { digitalOffer: selectedGift.digitalOffer }),
  			...(selectedGift.discountType && { discountType: selectedGift.discountType })
			}
    };

    return axios.post(config.apiUrl + "/wheels-history/", data);
  };

  return (
    !!wheelData.data && !wheelData.isLoading && (
      <>
        <div
          className={`bubble ${position || "right"}`}
          style={{ "--background-color": customStyle?.wheelColor }}
          onClick={() => handleClick()}
        >
          {!open ? (
            <img src="https://storage.googleapis.com/cdn_dokaa/media/gift.svg" alt='gift icon' width="40px" />
          ) : (
            <img src="https://storage.googleapis.com/cdn_dokaa/media/chevron.svg" alt='chevron icon' width="24px" />
          )}
        </div>
        <div
          id="bubble-modal"
          className={`${open ? "active" : ""} ${position || "right"}`}
        >
          <div className='welcome'>
            <h2>
              On a des surprises pour vous 😎
            </h2>
            <p>
              Gagnez des cadeaux {shop.name} en faisant tourner la roue 🎁
            </p>
          </div>
          <div className="content">
            <Wheel
              customStyle={customStyle}
              onSelectedItem={onSelectedItem}
              items={items}
              shop={shop}
              selectedItem={selectedItem}
              triggerWheel={triggerWheel}
              setTriggerWheel={setTriggerWheel}
            />

            {!end ? (
              <>
                {pickedCta.type === "form" && (
                  <Form
                    onSubmit={onSubmit}
                    setTriggerWheel={setTriggerWheel}
                    customStyle={customStyle}
                    shop={shop}
                  />
                )}
                {pickedCta.type === "google" && (
                  <GoogleReview
                    onSubmit={onSubmit}
                    setTriggerWheel={setTriggerWheel}
                    loadingThirdPart={loadingThirdPart}
                    setEnd={setEnd}
                    selectedItem={selectedGift}
                    customStyle={customStyle}
                    shop={shop}
                  />
                )}
                {pickedCta.type === "none" && (
                  <NoCounterpart 
                    onSubmit={onSubmit}
                    setTriggerWheel={setTriggerWheel}
                    customStyle={customStyle}
                    shop={shop}
                  />
                  
                )}
                {pickedCta.type === "noMoreCta" && (
                  <div className="end-dialog">
                    <span>
                      Vous avez déjà joué a ce jeu :(
                    </span>
                </div>
                )}
              </>
            ) : (
              <div className="end-dialog">
                <span>
                  Bravo, vous avez gagné <strong>{selectedGift?.digitalOffer ? `-${selectedGift?.name}${selectedGift?.discountType === "percent" ? "%" : "€"}` : selectedGift?.name}</strong>
                </span>
                <p>
                  Vous allez bientôt recevoir un email contenant votre cadeau !
                </p>
              </div>
            )}
          </div>
          <div className="dokaa-footer">
            <div>
              <a className="dokaa-cgu" target="_blank" rel="noreferrer" href="https://www.dokaa.app/dokaa-conditions-de-participation">
                  Conditions générales d'utilisation
              </a>
            </div>
            <div>
              Powered by{" "}
              <a className="dokaa-signature" target="_blank" rel="noreferrer" href="https://dokaa.app">
                Dokaa
              </a>
            </div>
          </div>
        </div>
        
      </>
    )
  );
};

export default App;


